
	import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';

	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import AuthenticationModalMixin from '@pixcap/ui-core/mixins/AuthenticationModalMixin';
	import {
		Getters as UserGetters,
		GetterTypes as UserGetterTypes,
		NAMESPACE as USER_NAMESPACE,
		SUBSCRIPTION_UPGRADE_CASE,
		UPGRADE_PLAN_TRIGGER_TYPE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';

	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import LibraryItemListLoader from '@/components/pixcap-library/shared/LibraryItemListLoader.vue';
	import {
		IAssetsLibraryState,
		LIBRARY_TAB,
		NAMESPACE as LIBRARY_NAMESPACE,
		PixcapLibraryFile,
		SEARCH_ROUTES,
	} from '@/models/store/pixcapassets.interface';
	import { LIBRARY_ITEM_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { actionsWrapper as LibraryActions, mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import LibraryTemplateDetailsThumbnails from '@/components/pixcap-library/library-templates/LibraryTemplateDetailsThumbnails.vue';
	import { actionsWrapper as ProjectActions } from '@/store/project/wrapper';
	import PixcapLibraryTemplate from '@/components/pixcap-library/shared/PixcapLibraryTemplate.vue';
	import MasonryWall from '@/components/pixcap-library/shared/MasonryWall.vue';
	import { setupBaseMetaTags } from '@/utils/seo';
	import { LibraryMockupItemType } from '@/constants/pixcapassets.constants';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import IconCaretRight from '@pixcap/ui-library/components/Icons/IconCaretRight.vue';
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';
	import ExploreItemTypeBanner from '@/components/banners/ExploreItemTypeBanner.vue';
	import PixcapDivThumbnail from '@pixcap/ui-library/components/PixcapDivThumbnail.vue';

	@Component({
		name: 'LibraryTemplateDetails',
		components: {
			PixcapDivThumbnail,
			ExploreItemTypeBanner,
			IconCaretRight,
			IconCrown,
			CaptionText,
			PixcapLibraryTemplate,
			IconLargeArrowRight,
			LibraryTemplateDetailsThumbnails,
			HeadingText,
			BodyText,
			Button,
			MasonryWall,
			LibraryItemListLoader,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				selectedLibraryTemplateSlug: (state: IAssetsLibraryState) => state.selectedLibraryTemplateSlug,
				isFetchingTemplateDetails: (state: IAssetsLibraryState) => state.isFetchingTemplateDetails,
				templateDetailsState: (state: IAssetsLibraryState) => state.templateDetailsState,
				isFetchingRelatedTemplates: (state: IAssetsLibraryState) => state.isFetchingRelatedTemplates,
				relatedTemplates: (state: IAssetsLibraryState) => state.relatedTemplates,
			}),
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
			}),
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
			}),
		},
	})
	export default class LibraryTemplateDetails extends Mixins(AuthenticationModalMixin) {
		@Prop() itemSlug: string;

		isFetchingTemplateDetails: IAssetsLibraryState['isFetchingTemplateDetails'];
		templateDetailsState: IAssetsLibraryState['templateDetailsState'];

		isFetchingRelatedTemplates: IAssetsLibraryState['isFetchingRelatedTemplates'];
		relatedTemplates: IAssetsLibraryState['relatedTemplates'];

		personalData: UserState['personalData'];
		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;

		templateInBundles: PixcapLibraryFile[] = [];
		relatedPackSlug: string = '';
		relatedPackId: string = null;
		isFetchingItemInPack: boolean = false;
		isShowAllTags = false;
		_handleScreenSize;
		minColumn: number | undefined = 2;
		columnGap: number | undefined = 24;
		rowGap: number | undefined = 24;
		LIBRARY_TAB = LIBRARY_TAB;
		SEARCH_ROUTES = SEARCH_ROUTES;

		@Watch('itemSlug')
		onItemSlugChange() {
			this.fetchLibraryTemplateDetails();
		}

		get isOwned() {
			if (this.isProOrAbove) return true;
			return this.personalData?.user?.purchaseHistory?.includes(this.templateDetails.itemId);
		}

		get isMockupTemplate() {
			const itemType = this.templateDetails?.itemType;
			return LibraryMockupItemType.includes(itemType);
		}

		get isAnimatedMockup() {
			return this.templateDetails?.itemType === LIBRARY_ITEM_TYPE.ANIMATED_MOCKUP_TEMPLATES;
		}

		get templateName() {
			return this.templateDetails.itemName.includes('3D') ? this.templateDetails.itemName : `${this.templateDetails.itemName} 3D Template`;
		}

		get thumbnailUrl() {
			if (!this.templateDetails) return null;
			return this.templateDetails.thumbnailUrl?.url;
		}

		get animationUrl() {
			if (!this.templateDetails) return null;
			return this.templateDetails.animationUrl?.url;
		}

		get thumbnailAltTag() {
			if (!this.templateDetails) return '';
			return this.templateName;
		}

		get templateDetails() {
			return this.templateDetailsState;
		}

		get detailsDescription() {
			if (this.templateDetails.description) return this.templateDetails.description;
			return this.$t('page.item_details.features.mockup_description', { item_name: this.templateDetails.itemName });
		}

		get templateTopic() {
			return this.templateDetails.topics?.[0];
		}

		get isProTemplate() {
			return this.templateDetails.isPro;
		}

		async fetchLibraryTemplateDetails() {
			if (this.isFetchingTemplateDetails) return;
			this.templateInBundles = [];
			await LibraryActions.fetchLibraryTemplateDetails(this.$store, this.itemSlug);
			if (this.templateDetails) {
				await this.fetchBundlesItem();
				if (this.isMockupTemplate) {
					await this.fetchRelatedMockup();
				} else {
					await this.fetchLibraryRelatedTemplate();
				}
			}
		}

		async fetchLibraryRelatedTemplate() {
			const ignoreIds = [this.templateDetails.itemId, ...this.templateInBundles.map((item) => item.itemId)];
			await LibraryActions.fetchRelatedTemplates(this.$store, {
				search: this.templateDetails.relatedQuery,
				ignoreIds,
				topic: this.templateTopic ? this.templateTopic.topicId : null,
			});
		}

		async fetchRelatedMockup() {
			const ignoreIds = [this.templateDetails.itemId];
			await LibraryActions.fetchRelatedTemplates(this.$store, {
				ignoreIds,
				itemType: this.templateDetails.itemType,
			});
		}

		async fetchBundlesItem() {
			this.isFetchingItemInPack = true;
			const pack = await LibraryActions.fetchPackItems(this.$store, this.templateDetails.itemId);
			this.templateInBundles = pack.packItems ?? [];
			this.relatedPackSlug = pack.slug;
			this.relatedPackId = pack.packId || null;
			this.isFetchingItemInPack = false;
		}

		handleScreenSize() {
			if (typeof window !== 'undefined') {
				if (window.innerWidth <= 520) {
					this.minColumn = 1;
					this.rowGap = 24;
					this.columnGap = 24;
				} else if (window.innerWidth > 520 && window.innerWidth <= 743) {
					this.minColumn = 2;
					this.rowGap = 24;
					this.columnGap = 24;
				} else if (window.innerWidth <= 1024) {
					this.minColumn = 3;
					this.rowGap = 24;
					this.columnGap = 24;
				} else if (window.innerWidth >= 1025 && window.innerWidth < 1440) {
					this.minColumn = 3;
					this.columnGap = 24;
					this.rowGap = 24;
				} else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
					this.minColumn = 4;
					this.columnGap = 24;
					this.rowGap = 24;
				} else {
					this.minColumn = 4;
					this.columnGap = 24;
					this.rowGap = 24;
				}
			}
		}

		handleClickTag() {
			LibraryMutations.setSelectedLibraryTemplateSlug(this.$store, null);
		}

		handleShowAllRelatedPack() {
			LibraryMutations.setSelectedLibraryTemplateSlug(this.$store, null);
			this.$router.push({
				name: 'LibraryBrandKitPackDetailsPage',
				params: {
					packSlug: this.relatedPackSlug,
				},
			});
		}

		handleShowRelatedResource() {
			LibraryMutations.setSelectedLibraryTemplateSlug(this.$store, null);
			this.$router.push({
				name: 'LibraryExploreSearchList',
				params: { tab: SEARCH_ROUTES.ITEM_MOCKUPS, searchQuery: this.templateDetails.itemName },
			});
		}

		_checkUserPermission(isMustPro = false, triggerReason?: string) {
			if (isMustPro) {
				if (!this.isOwned) {
					UserMutations.setUpgradeModalState(this.$store, {
						triggerCase: SUBSCRIPTION_UPGRADE_CASE.USE_PRO_ITEM,
					});
					return true;
				}
			}
			return false;
		}

		handleRemixScene() {
			if (this.templateDetails.isPro && !this.isAuthenticated) {
				this.showAuthenticationModal();
				return;
			}
			if (this.isAuthenticated && this._checkUserPermission(this.templateDetails.isPro, 'Template Details Edit')) return;
			ProjectActions.createDesignProject(this.$store, this.$router, { slug: this.templateDetails.slug }, 'Remix in Mix and Match', {
				'Item Name': this.templateDetails.itemName,
				'Item Slug': this.templateDetails.slug,
				'Item Type': this.templateDetails.itemType,
			});
		}

		async fetch() {
			await this.fetchLibraryTemplateDetails();
		}

		head() {
			const headMeta: any = {
				meta: [],
				script: [],
			};
			const metaTagsPaylod: any = {
				url: `${process.env.ROOT_URL}${this.$route.fullPath}`,
			};
			const templateDetailsState = this.templateDetailsState;
			if (templateDetailsState) {
				metaTagsPaylod.image = this.thumbnailUrl;
				const keyWord = templateDetailsState.itemName;

				metaTagsPaylod.title = this.$t('seo_meta.title_item_detail', { keyWord });
				metaTagsPaylod.description = this.isMockupTemplate
					? this.$t('seo_meta.description_item_mockup_detail', { keyWord })
					: this.$t('seo_meta.description_item_design_detail', { keyWord });
				metaTagsPaylod.script = {
					json: {
						'@context': 'http://schema.org',
						'@type': 'ImageObject',
						name: metaTagsPaylod.title,
						contentUrl: metaTagsPaylod.image,
						description: metaTagsPaylod.description,
					},
					type: 'application/ld+json',
					'data-test': 'image-object',
				};
			}
			setupBaseMetaTags(metaTagsPaylod, headMeta, this.$route);
			return headMeta;
		}

		countView() {
			if (this.templateDetails && process.client) LibraryActions.viewLibraryItem(this.$store, this.templateDetails.itemId);
		}

		mounted() {
			this.countView();
			this._handleScreenSize = this.handleScreenSize.bind(this);
			window.addEventListener('resize', this._handleScreenSize);
			this.handleScreenSize();
		}

		beforeDestroy() {
			window.removeEventListener('resize', this._handleScreenSize);
		}
	}
